/* You can add global styles to this file, and also import other style files */




.ribbon {
  width: 75px;
  height: 75px;
  overflow: hidden;
  position: absolute;
  bottom: 0px;
  right: 0px;

  span {
    position: absolute;
    display: block;
    width: 120px;
    padding: 5px 10px;
    text-align: center;
    left: -10px;
    bottom: 10px;
    transform: rotate(-45deg);
    text-transform: uppercase;
    font-size: .8em;
    background-color: rgba(0, 0, 0, .3);
    color: white;
  }
}